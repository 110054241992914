<template>
  <div>
    <!--  BTN    -->
    <b-button class="filter sch-btn" id="filter" v-b-toggle.dropdown-schedule :class="{buttonActive : showingSidebar}">
      <v-schedule-icon class="calendar-schedule-icon" />
    </b-button>

    <!--  SIDE BAR START    -->
    <b-sidebar id="dropdown-schedule" width="30em" right shadow no-header @shown="showSidebar" @hidden="hideSidebar">
      <!--    HEADER-->
      <div class="side-header">
        <div class="header-wrapper">
          <div>
            <Filters @filters="applyFilter" v-show="showFilters"/>
          </div>

          <div class="mr-auto" :class="{centered : showFilters}">
            <v-calendar
              class="calendar-icon openFilterDate"
              v-click-outside="(e) => shouldCloseDateFilter(e)"
            />

            <span
              class="header-text openFilterDate"
              v-click-outside="(e) => shouldCloseDateFilter(e)"
              >{{ this.date.locale("pt-br").format("DD [de] MMMM, dddd") }}</span
            >

            <date-picker
              id="dateFilter"
              v-model="filteredDate"
              @change="changeFilterDate"
              :key="dateKey"
              format="dddd[,] DD [de] MMMM"
              placeholder="Selecione uma data"
              :clearable="false"
              :lang="langDatePicker"
              class="full"
              :disabled="this.disabledDateFilter"
              :open="showFilterDate"
              :disabled-date="disabledDates"
              v-if="workingDays.length > 0"
              popupClass="datePickerPopup"
            />
          </div>

          <v-close class="close-icon-header" v-b-toggle.dropdown-schedule />
        </div>
      </div>

      <!--    BODY    -->
      <div class="side-body">
        <b-tabs
          active-nav-item-class="tab-item-active"
          active-tab-class="tab-item"
          content-class="mt-2"
          fill
          end
          @activate-tab="changeTab"
        >
          <b-tab title="Eventos do dia" class="tab-item" active lazy>
            <div class="tab-content">
              <div class="event-count-wrapper">
                <EventCount
                  :date="this.date.toDate()"
                  :key="'tabDayCount' + componentKey"
                  :filters="filters"
                />
                <ListEvent
                  :date="this.date"
                  :filters="filters"
                  :key="'tabDayList' + componentKey"
                />
              </div>
            </div>
          </b-tab>

          <b-tab title="Horários disponíveis" class="tab-item" lazy>
            <div class="tab-content">
              <div class="event-count-wrapper">
                <EventCount
                  :date="this.date.toDate()"
                  :key="'tabAvailableCount' + componentKey"
                  :filters="filters"
                />
                <ListAvaliableHours
                  :date="this.date.toDate()"
                  :key="'tabAvailableList' + componentKey"
                  :filters="filters"
                />
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </b-sidebar>
  </div>
</template>
<script>
import ScheduleIcon from "@/assets/icons/calendar-schedule.svg";
import Calendar from "@/assets/icons/calendar.svg";
import Close from "@/assets/icons/close.svg";

export default {
  components: {
    "v-schedule-icon": ScheduleIcon,
    "v-calendar": Calendar,
    "v-close": Close,
    EventCount: () => import("../components/EventCount"),
    ListEvent: () => import("../components/ListEvent"),
    ListAvaliableHours: () => import("../components/ListAvaliableHours"),
    Filters: () => import("../components/Filter"),
  },
  data() {
    return {
      clinic: JSON.parse(localStorage.getItem("clinic")),

      date: this.moment(),
      disabledDateFilter: false,
      langDatePicker: {
        formatLocale: {
          weekdaysMin: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
        },
      },
      dateKey: 0,
      filteredDate: [],
      showFilterDate: false,
      componentKey: 0,
      filters: {},
      workingDays: [],
      outages: [],
      showingSidebar: false,
      showFilters: true,
    };
  },
  created() {
    this.getWorkingDays();
    this.getOutages();
  },
  methods: {
    changeTab(n) {
      if (n === 0) {
        this.showFilters = true;
      } else {
        this.showFilters = false;
      }
    },
    showSidebar() {
      this.showingSidebar = true;
    },
    hideSidebar() {
      this.showingSidebar = false;
    },
    async getOutages() {
      try {
        const response = await this.api.getAllOutages(this.clinic.id);
        const data = response.data;

        const outages = [];

        data.forEach((outage) => {
          const start = new Date(outage.start + "T00:00:00");
          const end = new Date(outage.end + "T00:00:00");

          const outageDays = Math.floor((end - start) / (1000 * 60 * 60 * 24));

          for (let i = 0; i <= outageDays; i++) {
            const date = new Date(start.getTime() + i * 24 * 60 * 60 * 1000);

            outages.push(date);
          }
        });

        this.outages = outages;
      } catch (error) {
        console.error("Error retrieving outages:", error);
      }
    },
    async getWorkingDays() {
      try {
        const response = await this.api.getClinicScheduleConfig(this.clinic.id);
        const data = response.data.configs.days;

        const days = [
          { day: "DOMINGO", working: false },
          { day: "SEGUNDA-FEIRA", working: false },
          { day: "TERCA-FEIRA", working: false },
          { day: "QUARTA-FEIRA", working: false },
          { day: "QUINTA-FEIRA", working: false },
          { day: "SEXTA-FEIRA", working: false },
          { day: "SABADO", working: false },
        ];

        data.forEach((day) => {
          const index = days.findIndex((d) => d.day === day.day);
          if (index !== -1) {
            days[index].working = day.status;
          }
        });

        this.workingDays = days;
      } catch (error) {
        console.error("Error retrieving working days:", error);
      }
    },
    disabledDates(date) {
      const day = this.workingDays[date.getDay()];

      // Disable all dates that are not working days
      if (!day.working) {
        return true;
      }

      return false;
    },
    applyFilter(filter) {
      this.filters = filter;
    },
    changeFilterDate() {
      this.date = this.moment(this.filteredDate);

      this.componentKey += 1;
    },
    shouldCloseDateFilter(e) {
      if (e.target.classList.contains("openFilterDate")) {
        this.showFilterDate = !this.showFilterDate;
      } else if (
        e.target.classList.contains("mx-icon-left") ||
        e.target.classList.contains("mx-icon-right") ||
        e.target.classList.contains("mx-btn") ||
        e.target?.parentElement?.parentElement?.parentElement?.classList.contains(
          "mx-table"
        )
      ) {
        // Do nothing because the click is on the date picker
      } else if (this.showFilterDate) {
        this.showFilterDate = false;
      }
    },
  },
};
</script>

<style lang="scss">
.buttonActive {
  background-color: #E7ECFE !important;
}

.nav-tabs {
  margin-top: 2px;
}

.mx-datepicker-popup {
  margin-top: 40px;
}

div#dropdown-schedule {
  background-color: white !important;
  top: 17% !important;
  right: 0.8%;
  border-radius: 8px;
  border: 1px solid #c6ceeb;
  height: 82vh;

  #dateFilter {
    visibility: hidden;
    height: 0px;
    width: 0px;
  }

  .sch-btn {
    background-color: var(--Blue-100, #e7ecfe) !important;
    border: 1px solid #c6ceeb;
    border-radius: 8px;
  }

  .tab-content {
    height: 70.6vh;
    overflow: auto;
    overflow-x: hidden;
  }

  .header-wrapper {
    padding: 0px 24px;
    display: flex;
    align-items: center;

    .centered {
      margin-right: auto;
      margin-left: auto;
    }
  }

  .close-icon {
    fill: var(--neutral-500);
    height: 24px;
    width: 24px;
    opacity: 0.6;
    position: absolute;
    right: 24px;
    top: 14px;
  }

  .close-icon-header {
    fill: var(--neutral-500);
    height: 24px;
    width: 24px;
    opacity: 0.6;
  }

  .filter-icon {
    margin-right: 41px;
    height: 26px;
    width: 26px;
    padding: 4px;
  }

  .side-header {
    border-bottom: 1px solid #c6ceeb;
    padding-top: 6px;
    padding-bottom: 6px;
    font-size: 18px;
    font-weight: 500;

    background: white;
    border-top-left-radius: 10px;
  }

  .tab-item-active {
    font-weight: bold;
    color: #ff6b00 !important;
    border-right: 1px solid #ff6b00;
    border-top: 1px solid #ff6b00;
    border-left: 1px solid #ff6b00;
    //width: 210px;
    height: 44px;
    gap: 8px;
  }

  li.nav-item {
    a {
      color: var(--Type-Active, #525c7a);
    }

    border-top: 1px solid #c6ceeb;
    text-align: center;
    /* Text / Small */
    font-family: Nunito Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    /* 21px */
    width: 50%;
  }

  .calendar-icon {
    height: 20px;
    width: 22px;
    margin-right: 8px;
    margin-top: -4px;
  }

  .header-text {
    //styleName: Text / Large Regular;
    font-family: Nunito Sans;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
  }
}

.datePickerPopup {
  margin-left: 40px;
}
</style>
